// Out of house
import React, {memo} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

const BasicModal = ({
  buttonTitle,
  title,
  showStuff,
  fullWidth = true,
  handleOpen,
  handleClose,
  open,
  sx = { margin: "2%", background: "#e0e0e0", borderRadius: 1, disabledBackground:'red' },
  buttonSize = 'small',
  buttonClass = "bg-dark text-light font-weight-bold",
  titleClass = 'px-3 py-2',
  titleVariant='h4',
  disabled=false,
  disabledCompletely=false,
  variant='contained',
  color='primary'
}) => {
  return (
    <>
      <Button
        size={buttonSize}
        variant={variant}
        onClick={disabled ? null : () => handleOpen(true)}
        sx={sx}
        className={buttonClass}
        disabled={disabledCompletely}
        color={color}
      >
        {buttonTitle}
      </Button>

      <Dialog
        fullWidth={fullWidth}
        className="mx-auto"
        open={open}
        onClose={() => handleClose(false)}
        // scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent
          // scroll="paper"
          className="p-0 m-0 border border-white"
          style={{ backgroundColor: "#1D2124" }}
        >
          <div
            className="card"
            style={{ background: "#1D2124", border: "#ff5a3f" }}
          >
            <div className="card-header text-center text-light px-0" 
            style={{ position: "sticky", top: 0, zIndex: 1, background:'#1D2124'}}
            >
              <Typography variant={titleVariant} className={titleClass}>
                {title}
              </Typography>
            </div>
            {showStuff}
          </div>
        </DialogContent>

        <DialogActions
          style={{
            backgroundColor: "#1D2124",
            overflow: "hidden",
            margin: 0,
            padding: 0,
          }}
        >
          <Button onClick={() => handleClose(false)}>
            <span className="material-icons ml-1 text-secondary">&#xe5c9;</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(BasicModal)