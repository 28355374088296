import React from "react";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

const AccessKeyButton = ({size='small', classNameCSS=''}) => {
  return (
    <NavLink to="/Sign In" style={{ textDecoration: "none" }}>
    <Button
        size={size}
      variant="outlined"
      className={"bg-dark " + (classNameCSS)}
      style={{ color: "#ff5a3f", borderRadius: 5 }}
    >
      <span className="material-icons">&#xe0da;</span>
    </Button>
  </NavLink>
  );
};

export default AccessKeyButton;
