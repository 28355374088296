import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import auth from '../../services/authService';

const ProtectedRoute = ({ adminTrue = false }) => {
    const user = auth.getCurrentUser();
    const location = useLocation(); // Use the useLocation hook to get the location object

    if (!user) {
        // Redirect to sign-in if not authenticated
        // Pass the current location in the state so you can redirect back after logging in
        return <Navigate to="/sign in" replace state={{ from: location }} />;
    }

    if (adminTrue && !user.isAdmin) {
        // Redirect to dash if not admin
        // Pass the current location in the state so you can redirect back if necessary
        return <Navigate to="/" replace state={{ from: location }} />;
    }

    // Render children if authenticated (and admin if required)
    return <Outlet />;
};

export default ProtectedRoute;