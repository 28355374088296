// Out of house
import React, {memo} from 'react';
import Col from 'react-bootstrap/Col'

const ServeToDash = ({children, small=[12,0], med=[8,3], large=[8,1], pageBreak=true, forceTwelve=false}) => {
    return (
        <>
            <div className='container-fluid mt-4 bg-secondary' style={{background:'inherit'}}>
                <div className={'row justify-content-center p-0 ' + (forceTwelve ? 'pt-2' : 'pt-5')}>
                    <h1><br/></h1>
                    <Col 
                        xs={{span:small[0], offset:small[1]}} 
                        md={{span:med[0], offset:med[1]}} 
                        lg={forceTwelve ? {span:10, offset:-4} : {span:large[0], offset:large[1]}} 
                        className={'p-0'}
                    >
                    {/* Shit for the content of dashboard goes here 😃 */}
                        {children}
                    </Col>
                </div>
            </div>
            {pageBreak && <><br/><br/></>}
        </>
    );
}
 
export default memo(ServeToDash);