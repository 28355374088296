import React from 'react';

import { createRoot } from 'react-dom/client';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import { GlobalContextProvider } from "./store/globalContext";
import { Typography } from "@mui/material";
import { postFatalError } from './services/adminService';

// ErrorBoundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    // Log error details if needed
    const development = process.env.REACT_APP_API_URL === 'http://localhost:3901/api'; // Check if in development mode
    if (!development){
      // Log error details if not in development mode
      postFatalError({ error:error.toString(), errorInfo:errorInfo.componentStack });
    }

    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI when an error occurs
      return (
        <div className="container" style={{ marginTop: '33vh'}}>
          <div className="row">
        <div className="col-12 d-flex justify-content-center align-items-center text-center">
          <div>
            <Typography variant="h5" align="center" className="text-light">
          Oops! Something went wrong 
            </Typography>
            <Typography variant="p" align="center" className="text-light mx-auto">
          The error has been logged and we will fix it ASAP 🛠️
            </Typography>
            <br/>
            <br/>
            <Typography variant="p" align="center" className="text-light mx-auto">
          <span className='font-weight-bold text-dark'>Error:</span> {this.state.error.toString()}
            </Typography>
            <br/>
            <Typography variant="p" align="center" className="text-light mx-auto">
            <span className='font-weight-bold text-dark'>Error Location:</span> {this.state.errorInfo.componentStack.split(' at ')[1]}
            </Typography>
          </div>
        </div>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {React.Children.map(this.props.children, (child) => {
          return React.cloneElement(child, { onError: this.componentDidCatch });
        })}
      </React.Fragment>
    );
  }
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <GlobalContextProvider>
          <App />
        </GlobalContextProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

serviceWorkerRegistration.register();