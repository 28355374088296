import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

const ItemLink = ({name, children}) => {
    return (
        <ListItem >
            <ListItemIcon>
                {children}
            </ListItemIcon>
            <ListItemText primary={name} />
        </ListItem>
     );
}
 
export default ItemLink;