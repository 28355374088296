import http from "./httpService";
const apiEndpoint = "/data";


// # Admin related

export const updateETFFundies = (type) =>
  http
    .get(`${apiEndpoint}/updateAssets/${type}`)
    .then((res) => console.log(res));

export const updateETFTechnicals = (type) =>
  http
    .get(`${apiEndpoint}/updateAssets/${type}`)
    .then((res) => console.log(res));


// # Stock / user experience related
export const postAsset = (id, liveData=true) => 
  http.post(`${apiEndpoint}/`, { data: id, liveData }).then((res) => res);

export const getAssetNames = () =>
  http.get(`${apiEndpoint}/assetNames`).then((res) => res);


export const getSponsors = () =>
  http.get(apiEndpoint + "/getSponsors").then((res) => res);


export const getTrendEdgeHistoricalNew = async (
  assetNameList,
  settings,
  term = 520
) => {
  const {
    fastSMA,
    slowSMA,
    lookback,
    fastWeight,
    slowWeight,
    fastOverSlowWeight,
    macdWeight,
    adxWeight,
  } = settings;
  let assetString = "";
  assetNameList.forEach((asset) => (assetString += `${asset},`));
  const url = `${apiEndpoint}/trendEdgeHistoricalNew/${assetString}/${fastSMA}/${slowSMA}/${lookback}/${fastWeight}/${slowWeight}/${fastOverSlowWeight}/${macdWeight}/${adxWeight}/${term}`;
  const res = await http.get(url);
  return res;
};

export const getShareDataNew = (name) =>
  http.get(`${apiEndpoint}/shareDataNew/${name}`).then((res) => res);

export const getActiveOptions = (name) =>
  http.get(`${apiEndpoint}/activeOptions/${name}`).then((res) => res);

export const getLatestPerformanceDataDate = () => 
  http.get(`${apiEndpoint}/latestPerformanceDataDate`).then((res) => res);


export const getWklyReturns = async (
  assetNameList,
  settings,
  minTEdge,
  term = 520
) => {
  const {
    fastSMA,
    slowSMA,
    lookback,
    fastWeight,
    slowWeight,
    fastOverSlowWeight,
    macdWeight,
    adxWeight,
  } = settings;
  let assetString = "";
  assetNameList.forEach((asset) => (assetString += `${asset},`));
  const url = `${apiEndpoint}/wklyReturns/${assetString}/${fastSMA}/${slowSMA}/${lookback}/${fastWeight}/${slowWeight}/${fastOverSlowWeight}/${macdWeight}/${adxWeight}/${term}/${minTEdge}/?newVersion=${true}`;
  const res = await http.get(url);
  return res;
};

export const getSECValidation = async (
  ticker,
  qtr,
  reactChart = ""
) => {
  const url = `${apiEndpoint}/secValidation/${ticker}/${qtr}${reactChart ? `/${reactChart}` : ""}`;
  try {
    const res = await http.get(url);
    return res;
  } catch (error) {
    if (error.response && (error.response.status === 400 || error.response.status === 404)) {
      return error.response;
    } else {
      console.log("An error occurred:", error.message);
    }
  }
}

export const getAllFundNames = () =>
  http.get(`${apiEndpoint}/allFundNames`).then((res) => res);

export const getInstHoldings = cik => 
  http.get(`${apiEndpoint}/instHoldings/${cik}`).then((res) => res);


// Leadership history
export const getLeaderHistoryNew = () =>
  http.get(`${apiEndpoint}/leaderHistoryNew`).then((res) => res);

// # Macro Related

export const getUST = () =>
  http.get(`${apiEndpoint}/usTreasury`).then((res) => res);
export const getIpoHistory = () =>
  http.get(`${apiEndpoint}/ipoHistory`).then((res) => res);
export const getQqqHistory = () =>
  http.get(`${apiEndpoint}/qqqHistory`).then((res) => res);
export const getDivHistory = () =>
  http.get(`${apiEndpoint}/divHistory`).then((res) => res);
export const getNHNLHistory = (sectString) =>
  http
    .get(
      `${apiEndpoint}/nhnlHistory?sectString=${sectString}&sendSMA40=${true}`
    )
    .then((res) => res);
export const getAccumDistrHistory = () =>
  http.get(`${apiEndpoint}/accumDistrHistory`).then((res) => res);
export const getGoldDeathCross = () =>
  http.get(`${apiEndpoint}/goldDeathCross`).then((res) => res);
export const getSectorShareStats = () =>
  http.get(`${apiEndpoint}/sectorShareStats`).then((res) => res);
export const getUniqueInstitutions = () =>
  http.get(`${apiEndpoint}/uniqueInstitutions`).then((res) => res);
export const getSubstantialInstitutionHoldings = (institution) =>
  http
    .post(`${apiEndpoint}/substantialInstitutionHoldings`, { institution })
    .then((res) => res);

export const getWeeklyReview = (query = false) =>
  http
    .get(`${apiEndpoint}/weeklyReview` + (query ? query : ""))
    .then((res) => res);

export const getNHNLGDTableData = () => http.get(`${apiEndpoint}/nhnlgdTableData`).then((res) => res);

export const getTEIndexNew = (date) =>
  http.get(`${apiEndpoint}/teIndexNew/${date}`).then((res) => res);

// # Crypto Related
export const coinGecko = () =>
  http.get(`${apiEndpoint}/coinGecko`).then((res) => res);
export const cryptoExchanges = async () =>
  http.get(`${apiEndpoint}/cryptoExchanges`).then((res) => res);
export const exchangePairs = async (exchange) =>
  http.get(`${apiEndpoint}/exchangePairs/${exchange}`).then((res) => res);
export const checkBookWithPair = async (
  exchange,
  baseSymbol,
  quoteSymbol,
  limit
) =>
  http
    .get(
      `${apiEndpoint}/checkBook/${exchange}/${baseSymbol}/${quoteSymbol}/${limit}`
    )
    .then((res) => res);

// New version 4.0 related *** SCREENER ***
export const getValidIndustries = () =>
  http.get(`${apiEndpoint}/validIndustries`).then((res) => res);

export const getFundamentalData = (name) =>
  http.get(`${apiEndpoint}/fundamentalData/${name}`).then((res) => res);

export const getMinCompLen = (name, fastSMA, slowSMA, lookback) => 
  http.get(`${apiEndpoint}/minCompLen/${name}/${fastSMA}/${slowSMA}/${lookback}`).then((res) => res);

export const getFundsStats = () =>
  http.get(`${apiEndpoint}/fundsStats`).then((res) => res);

export const getWebinars = () =>
  http.get(`${apiEndpoint}/webinars`).then((res) => res);

export const getInsiderTransactions = (ticker) =>
  http.get(`${apiEndpoint}/insiderTransactions/${ticker}`).then((res) => res);