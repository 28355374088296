import http from './httpService'
import {getJwt} from './authService'

const apiEndpoint = '/users'

const userURL = (_id) => `${apiEndpoint}/${_id}`

export function register(user, showWelcomeMessage){
    return http.post(apiEndpoint,  {
        email: user.username,
        password: user.password,
        name: user.name,
        showWelcomeMessage
    })
}

export function logFeatureUsage(feature) {
    return http.post( apiEndpoint + "/featureUsage", {feature})
}

export const checkPW = async (_id, pw) => await http.get(apiEndpoint + "/checkLemons/" + _id + '/' + pw);
export const recoveryCheck = async (username) => await http.get(apiEndpoint + "/userExists/" + username);
export const recoverySubmit = async (username, recoveryCode) => await http.post(apiEndpoint + "/recoverySubmit", {username, recoveryCode}).then(res => res)
export const sendAcctRecovery = async (receivers) => await http.post(apiEndpoint + "/acctRecovery", {receivers}).then(res => res)

export const saveSettings = async (_id, update, type, token='') => {
    const url = `${userURL(_id)}/${type}`
    if (token) http.setJwt(getJwt())
    return await http.put(url, update).then(res => res);
}

export const saveSettingsHttp = async (_id, update, type, token='') => {
    const url = `${userURL(_id)}/${type}`
    if (token) http.setJwt(getJwt())
    return await http.put(url, update).then(res => res);
}

export async function getUser(_id){
    const url = apiEndpoint + "/getUser/" + _id
    try{
        const token = getJwt()
        if (token) http.setJwt(token)
    }
    catch(err){
        return {'error':'Error assigning JWT to HTTP'}
    }
    const getUserSettings = await http.get(url);
    if (getUserSettings.data.error)  return getUserSettings.data.error;
    else return getUserSettings.data;
}

export async function getStatus(_id){
    const url = apiEndpoint + "/status/" + _id
    try{
        const token = getJwt()
        if (token) http.setJwt(token)
    }
    catch(err){
        return {'error':'Error assigning JWT to HTTP'}
    }
    const {data} = await http.get(url);
    if (data.error)  return data.error;
    else return data;
}

export async function getWatchlists(_id){
    const url = apiEndpoint + "/watchlists/" + _id
    try{
        const token = getJwt()
        if (token) http.setJwt(token)
    }
    catch(err){
        return {'error':'Error assigning JWT to HTTP'}
    }
    const {data} = await http.get(url);
    if (data.error)  return data.error;
    else return data;
}

export const getWLTableData = (userID, watchlistName) =>
  http.get(`${apiEndpoint}/wlTableData/${userID}/${watchlistName}`).then((res) => res);