import React, {memo} from 'react';
import ReactLoading from "react-loading";
import { Section, Title, Article} from "./generic";

const Loading = ({title='', type, bgColor='inherit', width=false}) => {
    const bgColorFinal = bgColor ? bgColor : '#1D2124'
    return ( 
        <Section className='p-2'
            style={{background:bgColorFinal}}
        >
            {title ? <Title>{title}</Title> : ''}
            <Article key={type}>
                {width ? 
                <ReactLoading type={type} color="#ff5a3f" background={bgColorFinal} className='p-0' width={width}/> :
                <ReactLoading type={type} color="#ff5a3f" background={bgColorFinal} className='p-0'/>
            }
                
            </Article>
        </Section>
     );
}
 
export default memo(Loading);