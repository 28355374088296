// Out of House
import React, { useState, useRef, memo  } from 'react'
import InputField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import SearchIcon from '@mui/icons-material/Search';
// In house
import useWindowDimensions from '../common/windowDimensions';
const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT)

const SearchAutoFill = ({searchList, handleSubmit, clickSearchIcon, searchOpen}) => {
    const [input, setInput] = useState('')
    const [clearKey, setClearKey] = useState(1)
    const [display, setDisplay] = useState('none');
    const {width} = useWindowDimensions()
    const searchInput = useRef(null);

    const handleClickOpen = () => {
        clickSearchIcon()
        setInput('')
        return setDisplay('block')
    };

    const handleInputChange = async (event, value) => {
        if (value) setInput(value.toUpperCase())
        if (event.type==='click' && value) submitThis('', value)
    }

    const onDropdownClose = () => {
        setInput('')
        clickSearchIcon() 
        setDisplay('none')
    }

    React.useEffect(() => {
      if (searchOpen) {
        const { current: descriptionElement } = searchInput;
        if (descriptionElement !== null) descriptionElement.children[0].children[1].children[0].focus()
      }
    }, [searchOpen]);

    
    const submitThis = (event='', value='') =>{
        if (event) event.preventDefault()
        let search = value ? value : input
        if (String(search).includes('.X')) search = String(search).replace('.X','.x')
        if (!searchList.includes(search)) setInput('')
        else {
            handleSubmit(search)
            setInput('')
        }

    // This is a case where submit is handled using enter
    // Otherwise, when clicked from dropdown, we handle differently in onDropdownClose
        if (width < 820 && !value) {
            clickSearchIcon() 
            setDisplay('none')
        }
        if (clearKey === 1) return setClearKey(2)
        return setClearKey(1)
    }

    return (
    <>
        {width < 960 ? 
            <>
                {!searchOpen &&
                    <div 
                        style={{color:'#4682B4', border:'none'}}
                        onClick={handleClickOpen}
                        className='btn-sm mr-1'
                    >
                        <SearchIcon fontSize='large'/>
                    </div> 
                }
                <div className="row justify-content-end" style={{display:display}}>
                    <form
                        onSubmit={submitThis}
                        className="form-group my-1 mx-0 p-0 text-light"
                        style={{width:`${width/1.5}px`, borderRadius:'5px'}}
                    >
                        <Autocomplete
                            key={clearKey}
                            clearOnBlur={true}
                            filterOptions={filterOptions}
                            onInputChange={handleInputChange}
                            onChange={handleInputChange}
                            onClose={onDropdownClose}
                            popupIcon={false}
                            options={searchList ? searchList : ['Error']}
                            id='tickerInputBar'
                            size='small'
                            className='p-0 m-0 text-light'
                            style={{background:'#1D2124', color:'white', padding:0, margin:0}}
                            color='white'
                            ref={searchInput}
                            renderInput={(params) => (
                                <InputField
                                    {...params}
                                    size="small"
                                    margin="normal"
                                    variant="filled"
                                    id='tickerInputBar'
                                    label={
                                        <div className="d-flex flex-row align-items-center text-light">
                                            <SearchIcon fontSize='small' className='mr-2'/>Ticker Search
                                        </div>
                                    }
                                    className='p-0 m-0'
                                    value={input}
                                />
                            )}
                        />
                        
                    </form>
                </div>
    </>
        :
            <div className="d-flex flex-row align-items-center text-light">
                <form
                    onSubmit={submitThis}
                    id={'resetForm'}
                    className="form-group my-1 mx-0 p-0 text-light"
                    style={{width:`${width/6}px`, borderRadius:'5px'}}
                >
                    <Autocomplete
                        freeSolo
                        key={clearKey}
                        filterOptions={filterOptions}
                        options={searchList ? searchList : [':)']}
                        id='tickerInputBar'
                        size={'small'}
                        className='p-0 m-0 text-light'
                        onInputChange={handleInputChange}
                        style={{background:'#1D2124', color:'white', padding:0, margin:0}}
                        color='white'
                        renderInput={(params) => (
                            <InputField
                                {...params}
                                size="small"
                                margin="normal"
                                variant="filled"
                                id='tickerInputBar'
                                style={{background:'#343a40'}}
                                label={
                                    <div className="d-flex flex-row align-items-center text-light">
                                        <SearchIcon fontSize='small' className='mr-2'/>Search Ticker
                                    </div>
                                }
                                className='p-0 m-0'
                                value={input ? input : ''}
                            />
                        )}
                    />
                </form>
            </div>
       }
    </>
    );
}

export default memo(SearchAutoFill);